/*
Altered Side Matter
Plugin: Side Matter
Author: Christopher Setzer
*/


var isResizing; // Used for resize-timeout function

function placeNotes() {
  for (n = 1, refCount = $('a.side-matter-ref').length; n <= refCount; n++) { // Iterate through notes and position each
    var noteAdjust = '1';
    var ref = '#ref-' + n + ''; // Reference anchor
    var note = '#note-' + n + ''; // Sidenote
    var main = '.main'; // Sidenote
    var refPosition = $(ref).offset().top; // Position of reference anchor
    var blockPosition = $(main).offset().top; // Position of sidenote
    var noteOffset = refPosition - noteAdjust - blockPosition; // Get current offset between reference and note, minus noteAdjust
    var finalOffset = (noteOffset < 0) ? 0 : noteOffset; // If offset is negative, set to 0 (prevents layout problems)
    $(note).css('top', finalOffset); // Position note
  }
}
$(document).ready(function() { // Loop twice to correct any misplacements on first load

  for (i = 1; i <= 2; i++) {
    placeNotes();
  } // Run loop twice to correct initial misplacements
});

$(window).load(function() { // Position notes and fade in
  for (i = 1; i <= 2; i++) {
    placeNotes();
  } // Run loop twice to correct initial misplacements
});

$(window).resize(function() { // Reposition notes on resize/zoom
  var timeoutInterval = 600; // Time (in ms) to throttle the re-positioning loop, preventing script overload on resize (default: 600)
  function doneResizing() {
    for (i = 1; i <= 2; i++) {
      placeNotes();
    }
  }
  clearTimeout(isResizing);
  isResizing = setTimeout(doneResizing, timeoutInterval);
});